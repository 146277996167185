/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from "react";
import styled from "styled-components";
import { Header, Footer } from "../components";

const ContactPageStyles = styled.div`
  padding: 80px 80px;
  h1 {
    text-align: center;
    padding-bottom: 20px;
  }
  .contact-us-container {
    width: 33.333%;
    margin: auto;
  }
  .address-container {
    text-align: center;
    padding-bottom: 70px;
  }

  p,
  ul,
  legend,
  label {
    color: ${(props) => props.theme.text.paragraph};
  }
  label {
  }
  .field-container {
    margin: 0 0 24px;
    padding: 0;
    border: 0;
    display: flex;
  }
  .field-title {
    margin-left: 2%;
  }
  .field {
    margin-left: 2%;
    width: 100%;
  }
  .field.name-container {
    margin-left: 2%;
  }
  .field.name-container input {
    width: 98%;
  }
  .field input,
  .field textarea {
    width: 100%;
    font-size: 12px;
    padding: 12px;
    margin: 6px 0 4px;
    border: 1px solid #ccc;
    background: #fafafa;
    color: #000;
    font-family: sans-serif;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .field-label {
    font-size: 12px;
  }
  .form-submit {
    border-width: 2px;
    border-style: solid;
    background-color: transparent;
    color: ${(props) => props.theme.text.darkest};
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 1em 2.5em;
    transition: 0.1s background-color linear, 0.1s color linear;
    margin-left: 2%;
  }
  .form-submit:hover {
    cursor: pointer;
    color: #fff;
    background-color: ${(props) => props.theme.text.darkest};
  }
  .field-container.message textarea {
    min-height: 100px;
  }
  .thank-you-container {
    padding: 17px;
    text-align: center;
  }

  .location-map-container {
    border: 0;
    padding: 40px 0;
    height: 440px;
    iframe {
      width: 100%;
      border: 0;
      filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* Firefox 10+ */
      -webkit-filter: grayscale(99%); /* Chrome 19+ & Safari 6+ */
      -webkit-backface-visibility: hidden; /* Fix for transition flickering */
    }
  }

  @media screen and (max-width: 640px) {
    padding: 0 17px;
    .contact-us-container {
      width: 100%;
    }
    .address-container {
      padding: 70px;
    }
  }
`;

const ContactPage = () => (
  <main>
    <Header />
    <ContactPageStyles>
      <h1>Contact Us</h1>
      <div className="contact-us-container">
        <div className="address-container">
          <p>Zerrtech</p>
          <p>1020 W Main St #330</p>
          <p>Boise, ID 83702</p>
        </div>
        <form
          name="contact"
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          action="/thank-you-form-submission"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <fieldset className="field-container">
            <legend className="field-title">
              Name <sup>*</sup>
            </legend>
            <div className="field name-container">
              <label>
                <input type="text" name="first-name" id="first-name" required />
                <span className="field-label">First Name</span>
              </label>
            </div>
            <div className="field name-container">
              <label>
                <input type="text" name="last-name" id="last-name" required />
                <span className="field-label">Last Name</span>
              </label>
            </div>
          </fieldset>
          <fieldset className="field-container">
            <legend className="field-title">
              Email Address <sup>*</sup>
            </legend>
            <div className="field">
              <label>
                <input type="email" name="email" id="email" required />
              </label>
            </div>
          </fieldset>
          <fieldset className="field-container">
            <legend className="field-title">
              Subject <sup>*</sup>
            </legend>
            <div className="field">
              <label>
                <input type="text" name="subject" id="subject" required />
              </label>
            </div>
          </fieldset>
          <fieldset className="field-container message">
            <legend className="field-title">
              Message <sup>*</sup>
            </legend>
            <div className="field">
              <label>
                <textarea type="text" name="message" id="message" required />
              </label>
            </div>
          </fieldset>
          <div className="submit-button-container">
            <button type="submit" className="form-submit">
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="location-map-container">
        <iframe
          title="zerrtech-location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2888.4765917417244!2d-116.20856258482921!3d43.617438562761045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54aef8e42dddb381%3A0xd8b7ab7e147c2829!2s1020%20W%20Main%20St%2C%20Boise%2C%20ID%2083702!5e0!3m2!1sen!2sus!4v1630513222012!5m2!1sen!2sus"
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
        />
      </div>
    </ContactPageStyles>
    <Footer />
  </main>
);

export default ContactPage;
